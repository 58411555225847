import React, { Component } from 'react';
import './index.scss'
class CoralCafe extends Component {
  render() {
    return (
      <div className="coralcafe">
      </div>
    );
  }
}

export class Header extends Component {
  render() {
    return (
      <div className="order-benefits-wrapper">
        <div className="order-benefits">

          <div className="text">
            {/* <p>Geoge's Pizza</p> */}
            <p>Order Delivery & Togo Below</p>
            <p className="large">Free Delivery</p>
            {/* <p className="large">5% Cashback</p> */}
            <small>*free for over $20, under 2 mile, 3 mile max.</small>


          </div>


        </div>
      </div>
    );
  }
}

export default CoralCafe;
